import { CircularProgress } from '@mui/material'
import moment from 'moment'
import classNames from 'classnames'
import styles from '@/styles/Waitlist.module.scss'

export default function PartyFormLoyaltyLookup({
  isGuestLookingUp,
  isGuestLookupSuccess,
  loyaltyData,
}) {
  return (
    <>
      <div
        id="party-loyalty-notifier"
        className={classNames(styles.partyFormLoyaltyLookup)}
      >
        {isGuestLookingUp ? (
          <CircularProgress
            size={20}
            className={classNames(styles.partyFormLoyaltySpinner)}
          />
        ) : (
          <>
            {isGuestLookupSuccess === true && (
              <span className={classNames(styles.partyFormLoyaltySuccess)}>
                <img
                  src="/icons/circle-check.svg"
                  alt=""
                  width="20"
                  style={{ marginRight: '4px' }}
                />{' '}
                Match Found
              </span>
            )}
            {isGuestLookupSuccess === false && (
              <span className={classNames(styles.partyFormLoyaltyFailure)}>
                First Visit!
              </span>
            )}
          </>
        )}
      </div>
      {!isGuestLookingUp && loyaltyData && (
        <div
          id="party-form-lookup-data"
          className={classNames(styles.partyFormLookupData)}
        >
          <span style={{ fontWeight: 600 }}>Past Visits:</span>{' '}
          {loyaltyData?.totalVisits}
          <br />
          <span style={{ fontWeight: 600 }}>Last Visit:</span>{' '}
          {moment(loyaltyData.lastVisit?.orderColumn)?.calendar()}
          {/* {moment(loyaltyData.lastVisit?.createdAt).format("MM/DD/YYYY")} */}
        </div>
      )}
    </>
  )
}
