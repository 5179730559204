import { useField } from 'formik'
import Textarea from '@/components/common/forms/Textarea'
import { HelperText, FieldWrapper } from '@/components/common/forms/Common'

function TextareaField(props) {
  const { label, value, ...rest } = props
  const [field, meta] = useField(rest)

  return (
    <FieldWrapper>
      <Textarea label={label} value={value} {...field} {...rest} />
      <HelperText hasError={true}>
        {meta.touched && meta.error ? meta.error : null}
      </HelperText>
    </FieldWrapper>
  )
}

export default TextareaField
