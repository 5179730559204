import { useState, useEffect, useCallback } from "react"
import { useDispatch } from "react-redux";
import { setAlert, setIsAlertOpen } from "@/store/slices/alertSlice"
import useAudioTone from "./useAudioTone";
import { ALERT_TONES } from "@/consts/audio-tones"
import { useTypedSelector } from "../store";
import useFilteredParties from "./useFilteredParties";
import moment from "moment";

const useNewPartyAlert = () => {
    const [alerts, setAlerts] = useState([]);
    const [lastAlertTime, setLastAlertTime] = useState(moment());
    const [alertableParties, setAlertableParties] = useState([]);
    const {
        parties,
        isValidating: isPartiesLoading,
        mutateParties,
        responseMeta: partiesMeta,
    } = useFilteredParties()
    const tone = useAudioTone(ALERT_TONES.newSciAlert);
    const alert = useTypedSelector((state) => state.alert);
    const dispatch = useDispatch();

    const { isAlertOpen } = alert;

    const createAlertPayload = (party: any) => {
        return {
            isOpen: true,
            anchor: "bottom-left",
            type: "info",
            title:
            `${party?.guest?.name || 'A guest'} has joined the waitlist.`,
            message: `This guest added themself via self check-in.`,
            keepOpen: false,
        }
    }

    const filterSciParties = () => {
        return parties.filter(item => {
            return item.source === 'self-check-in' 
                && !alertableParties.includes(item)
                && moment(item.createdAt).isAfter(lastAlertTime)
        })
    }

    const addNewAlertableParties = (partyFilter: () => any[]) => {
        const filteredParties = partyFilter()
        .sort((a, b) => {
            return a.createdAt > b.createdAt ? 1 : -1
        })

        const newAlerts = filteredParties.map(item => {
            tone.play();
            return createAlertPayload(item)
        })

        if (filteredParties.length > 0) {
            setAlertableParties(filteredParties);
            setAlerts(newAlerts)
            setLastAlertTime(moment(filteredParties[filteredParties.length - 1].createdAt));
        }
    }

    const dispatchNextAlert = () => {
        if (alerts[0]) {
            dispatch(
                setAlert(alerts[0])
            )
            setAlertableParties(alertableParties.slice(1))
            setAlerts(alerts.slice(1))
        }
    }

    useEffect(() => {
        addNewAlertableParties(filterSciParties);
        dispatchNextAlert();
    }, [parties])

    useEffect(() => {
        if (!isAlertOpen) {
            dispatchNextAlert();
        }
    }, [alerts, isAlertOpen])

    return alerts;

}

export default useNewPartyAlert