import { NimbleEmoji } from 'emoji-mart'
import iconData from 'emoji-mart/data/twitter.json'
import styles from '@/styles/Waitlist.module.scss'
import { ParsedCustomField } from '@/api/interface/custom-field'
import ParagraphWithLines from './ParagraphWithLines'
import dayjs from 'dayjs'
import { ErrorBoundary } from './ErrorBoundary'
import { useMemo, useEffect } from 'react'
import CustomFieldEditableField from './CustomFieldEditableField'
import { parseCfSettings } from '@/utils/custom-field-utils'
import { Party } from '@/api/interface/party'
interface Props {
  party: Party
  customField: ParsedCustomField
  index: number
  lastIndex: number
}

export default function CustomFieldActionTooltipEntry({
  customField,
  party,
}: Props) {
  const queueSelectValue = useMemo(() => {
    if (customField.type === 'queueselect') {
      // there may be multiple values mapped to the same valueMeta
      // need to get a full map of values first
      const valueOption = customField?.options?.filter((option) => {
        option.value === customField.value
      })[0]
      return '' // valueOption.value
    }
    return null
  }, [customField])

  const cfType = customField.type
  const settings = useMemo(
    () => parseCfSettings(customField?.settings),
    [customField]
  )

  const formatValue = (value, type) => {
    if (type === 'time') {
      const datestring = dayjs().format('YYYY-MM-DD').toString()
      return dayjs(`${datestring} ${value}`).format('h:mm A').toString()
    }

    if (type === 'date') {
      return dayjs(customField.value).format('MM/D/YYYY').toString()
    }
    return value
  }

  return (
    <ErrorBoundary fallback={''}>
      <div className={styles.entrySingleField}>
        <p className={styles.entrySingleFieldTitle}>{customField.name}</p>

        {customField?.options?.length > 0 ? (
          //* if value is array, map over values and find where option.value = value. show icon
          Array.isArray(customField.value) ? (
            customField.value.map((val, i) => {
              const option = customField.options.find(
                (y) => y.value?.trim() === val.trim()
              )

              return (
                <div key={i} className={styles.entrySingleFieldValueBlock}>
                  {option.icon && (
                    <NimbleEmoji
                      emoji={option.icon}
                      set="twitter"
                      data={iconData}
                      size={20}
                    />
                  )}
                  <p className={styles.entrySingleFieldValue}>
                    {formatValue(val, cfType)}
                  </p>
                </div>
              )
            })
          ) : (
            //* if value is not array, find where option.value = value. show icon
            <div className={styles.entrySingleFieldValueBlock}>
              {customField.options.find(
                (x) => x.value?.trim() === customField.value?.trim()
              ) &&
                customField.options.find(
                  (x) => x.value?.trim() === customField.value?.trim()
                ).icon &&
                customField.type !== 'queueselect' && (
                  <NimbleEmoji
                    emoji={
                      customField.options.find(
                        (x) => x.value?.trim() === customField.value?.trim()
                      ).icon
                    }
                    set="twitter"
                    data={iconData}
                    size={20}
                  />
                )}

              <p
                className={styles.entrySingleFieldValue}
                data-testid={`cfval: ${customField.name}`}
              >
                {queueSelectValue
                  ? queueSelectValue
                  : formatValue(customField.value, customField.type)}
              </p>
            </div>
          )
        ) : (
          //* if no options, show value, no icon
          <div
            className={
              !settings?.isDynamic ? styles.entrySingleFieldValueBlock : null
            }
          >
            {/** if dynamic, show editable field */}
            {settings?.isDynamic ? (
              <CustomFieldEditableField
                party={party}
                customField={customField}
              />
            ) : (
              <ParagraphWithLines
                className={styles.entrySingleFieldValue}
                text={formatValue(customField.value, customField.type)}
              />
            )}
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
