import MultiselectField from '@/components/common/fields/MultiselectField'
import SelectField from '@/components/common/fields/SelectField'
import TextareaField from '@/components/common/fields/TextareaField'
import InputField from '@/components/common/fields/InputField'
import { CustomField } from '@/api/interface/custom-field'
import CheckboxInput from '@/components/common/forms/formik/CheckboxInput'
import { FormControl, FormGroup, FormLabel, RadioGroup } from '@mui/material'
import RadioInput from '@/components/common/forms/formik/RadioInput'
import DatePickerInput from '@/components/common/forms/formik/DatePickerInput'
import SimpleTimePickerFieldGroup from '@/components/common/forms/formik/SimpleTimePickerFieldGroup'
import classNames from 'classnames'

interface Props {
  customFields: Array<CustomField>
  handleQueueIdChange?: Function
  values?: any
  setFieldValue?: Function
  setErrors?: Function
  setTouched?: Function
  currentQueueSelectOption?: string
  location?: string
  publicQueueSelectHandler?: Function
  customQueueSelectValue?: string
}

export default function CreateEditPartyCustomFields({
  customFields,
  customQueueSelectValue,
}: Props) {
  const mapOptions = (options: CustomField['options'], type: string) => {
    return options?.map((x) => ({
      ...x,
      label: x.value,
      value: x.value,
      data: { ...x.data, icon: x.icon, valueMeta: x.valueMeta },
    }))
  }

  return (
    <>
      {customFields?.map((cf, i) => {
        const hideField = cf.hideField
        const fieldProps = {
          key: i,
          name: `customFields[${i}].value`,
          label: cf.name,
          required: cf.isRequired,
          icon: cf.icon,
          disabled: cf.disabled,
          settings: cf.settings,
        }
        const selectOptions = {
          options: mapOptions(cf.options, cf.type),
        }

        switch (cf.type) {
          case 'checkbox':
          // return (
          //   <FormControl>
          //     <FormLabel>{fieldProps.label}</FormLabel>
          //     <FormGroup>
          //       {selectOptions.options.map((x, i) => (
          //         <CheckboxInput label={x.label} />
          //       ))}
          //     </FormGroup>
          //   </FormControl>
          // )
          case 'multiselect':
            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <MultiselectField {...fieldProps} {...selectOptions} />
              </div>
            )
          case 'radio':
          // return (
          //   <FormControl>
          //     <FormLabel>{fieldProps.label}</FormLabel>
          //     <RadioGroup
          //       name={fieldProps.name}
          //       //   defaultValue={selectOptions.options[0]?.value}
          //     >
          //       {selectOptions.options.map((x, i) => (
          //         <RadioInput key={i} label={x.label} value={x.value} />
          //       ))}
          //     </RadioGroup>
          //   </FormControl>
          // )
          case 'select':
            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <SelectField
                  fullWidth
                  showEmptyOption
                  {...fieldProps}
                  {...selectOptions}
                  />
              </div>
            )

          case 'text':
            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <TextareaField fullWidth={true} {...fieldProps} />
              </div>
            )
          case 'number':
            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <InputField
                  type={cf.type}
                  // min={cf.settings?.min || 0}
                  // max={cf.settings?.max || 5000}
                  // step={cf.settings?.step || 1}
                  fullWidth={true}
                  {...fieldProps}
                />
              </div>
            )

          case 'date':
            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <DatePickerInput
                  label={'datepicker'}
                  {...fieldProps}
                />
              </div>
            )

          case 'email':
          case 'tel':
          case 'string':
            const inputType = cf.type === 'string' ? 'text' : cf.type

            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <InputField type={inputType} fullWidth={true} {...fieldProps} />
              </div>
            )

          case 'time':
            return (
              <div key={i} className={classNames(hideField && 'hidden')}>
                <SimpleTimePickerFieldGroup {...fieldProps} />
              </div>
            )
          case 'queueselect':
            return (
              <input
                type="hidden"
                value={customQueueSelectValue || ''}
                {...fieldProps}
              ></input>
            )
          default:
            console.error('Cannot render customfield with unknown type', cf)
        }
      })}
    </>
  )
}
