import { useField } from 'formik'
import Multiselect from '@/components/common/forms/Multiselect'
import { HelperText } from '@/components/common/forms/Common'
import { getDefaultCustomFieldValue } from '@/utils/util-functions'

export default function MultiselectField({
  helpText = null,
  defaultValue = null,
  ...rest
}) {
  const [field, meta, helpers] = useField({
    ...rest,
    type: 'select',
    multiple: true,
  })

  return (
    <div style={{ marginBottom: '0.25rem' }}>
      <Multiselect
        {...field}
        {...rest}
        defaultValue={
          defaultValue ||
          field.value ||
          getDefaultCustomFieldValue('multiselect')
        }
        onChange={(values) => {
          helpers.setValue(values.map((v) => v.value))
        }}
      />
      {helpText && <HelperText>{helpText}</HelperText>}
      {meta.touched && meta.error && (
        <HelperText hasError={true}>{meta.error}</HelperText>
      )}
    </div>
  )
}
