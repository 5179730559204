import { useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import styles from './DatePickerField.module.scss'
import Label from '../../forms/Label'
import { HelperText } from '../../forms/Common'
import { parseISO, format, sub, max } from 'date-fns'
import classNames from 'classnames'
import { CalendarIcon } from '@heroicons/react/24/solid'
import { isValidDateFieldValue } from '@/utils/validators'

export interface DatePickerFieldProps {
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  helperText?: string
  label?: string
  name?: string
  value?: string
  filterDates?: {
    ageLimit?: number
  }
  useTouched?: boolean
  isTouched?: boolean
  handleChange?: (arg: string) => void
  handleBlur?: () => void
}

const DatePickerField = ({
  disabled,
  required,
  hasError,
  helperText,
  label,
  name,
  value,
  filterDates,
  useTouched,
  isTouched,
  handleChange,
  handleBlur,
}: DatePickerFieldProps) => {
  const [startDate, setStartDate] = useState(null)
  const [hasFocus, setHasFocus] = useState(false)

  const handleFocus = () => {
    setHasFocus(true)
  }

  const onBlur = () => {
    setHasFocus(false)
    handleBlur()
  }

  const isUsingAgeFilter = useMemo(() => {
    return typeof filterDates?.ageLimit === 'string'
  }, [filterDates])

  const maxDate = useMemo(() => {
    if (filterDates?.hasOwnProperty('ageLimit') && filterDates?.ageLimit) {
      const olderThan = typeof filterDates?.ageLimit === 'string' ? parseInt(filterDates?.ageLimit) : filterDates?.ageLimit
      const filterStartDate = sub(new Date(), { years: olderThan })

      return filterStartDate
    }
    return null
  }, [filterDates])

  const filterAge = useCallback((date) => {
    if (filterDates?.hasOwnProperty('ageLimit') && filterDates?.ageLimit) {
      const olderThan = typeof filterDates?.ageLimit === 'string' ? parseInt(filterDates?.ageLimit) : filterDates?.ageLimit
      const filterStartDate = sub(new Date(), { years: olderThan })

      return date <= filterStartDate
    }
    return null
  }, [filterDates])

  const filterHelperText = useMemo(() => {
    if (filterDates?.ageLimit) {
      return `Must be older than ${filterDates?.ageLimit}`
    }
    return null
  }, [filterDates])

  useEffect(() => {
    const parsedValue =
      (typeof value === 'string' && value.length === 0) || value === undefined || !isValidDateFieldValue(value)
        ? null
        : parseISO(value)

    setStartDate(parsedValue || null)
  }, [value, maxDate, filterDates, isUsingAgeFilter])

  return (
    <div className={styles['date-picker-field']}>
      {label && <Label>{`${label}${Boolean(required) ? ' *' : ''}`}</Label>}
      <DatePicker
        name={name}
        showIcon
        toggleCalendarOnIconClick
        popperPlacement="top"
        popperModifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ]}
        wrapperClassName={classNames(styles['wrapper'])}
        className={classNames(
          styles['input'],
          hasFocus && !hasError ? styles['focus'] : null,
          useTouched ? isTouched && hasError && styles['error'] : hasError && styles['error']
        )}
        disabled={disabled}
        selected={startDate}
        onFocus={handleFocus}
        onBlur={onBlur}
        onChange={(date) => {
          if (date) {
            const dateString = format(date, 'yyyy-MM-dd')
            handleChange(dateString)
          } else {
            handleChange('')
          }
        }}
        icon={<CalendarIcon className="scale-125" />}
        filterDate={filterDates?.ageLimit && filterAge}
        maxDate={maxDate}
        placeholderText={'MM/DD/YYYY'}
        customInput={<input type="text" name={name} />}
        dateFormat="MM/dd/yyyy"
      />
      {filterHelperText && <HelperText hasError={false}>{filterHelperText}</HelperText>}
      <HelperText hasError={useTouched ? isTouched && hasError : hasError}>{useTouched ? isTouched && helperText : helperText}</HelperText>
    </div>
  )
}

export default DatePickerField
