import DatePickerField from '../../fields/DatePickerField.tsx'
import { useField } from 'formik'
import { isValidDateFieldValue } from '@/utils/validators'
import { useEffect, useMemo } from 'react'

export interface DatePickerInputProps {
  label?: string
  name: string
  disabled?: boolean
  hasError?: boolean
  helperText?: string
  required?: boolean
  value?: string
  settings?: string
}

const DatePickerInput = ({
  label,
  name,
  disabled,
  required,
  settings,
}: DatePickerInputProps) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = (value: string) => {
    helpers.setValue(value)
  }

  const handleBlur = () => {
    if (!meta.touched) {
      helpers.setError(undefined)
    }
    helpers.setTouched(true, true)
  }

  const parsedSettings = useMemo(() => {
    return JSON.parse(settings)
  }, [settings])

  return (
    <DatePickerField
      name={name}
      label={label}
      required={required}
      value={field?.value}
      disabled={disabled}
      hasError={Boolean(meta?.error)}
      helperText={meta?.error}
      handleChange={handleChange}
      handleBlur={handleBlur}
      filterDates={parsedSettings.dateRangeLimit}
      useTouched={true}
      isTouched={meta?.touched}
    />
  )
}

export default DatePickerInput
