import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { optionsFromKeys, range } from '@/utils/helper-functions'
import {
  getSelectedObjectById,
  formatPhoneNumber,
  printAxiosErrors,
} from '@/utils/util-functions'
import { setIsSlideOpen } from '@/store/slices/uiSlice'
import { setAlert, setIsAlertOpen } from '@/store/slices/alertSlice'
import {
  convertSecondsToTimeStr,
  findNearestStdInterval,
  getStdWaitTimeIntervals,
  isStdWaitTime,
} from '@/utils/wait-times'
import {
  SlideActionButtons,
  SlideContainer,
  SlideContent,
} from '@/components/common/Slide'
import buttonStyles from '@/styles/Buttons.module.scss'
import classNames from 'classnames'
import SlideHeading from '@/components/common/SlideHeading'
import SelectField from '@/components/common/fields/SelectField'
import InputField from '@/components/common/fields/InputField'
import { getGuestByPhoneNumber } from '@/api/nextme/guests'
import TextareaField from '@/components/common/fields/TextareaField'
import {
  PartyStatusEnum,
  PartyStatusNotBookingEnum,
} from '@/consts/PartyStatusEnum'
import CreateEditPartyCustomFields from '@/components/pages/waitlist/CreateEditParty/CreateEditPartyCustomFields'
import Button from '@/components/common/Button'
import PartyFormLoyaltyLookup from '@/components/pages/waitlist/CreateEditParty/PartyFormLoyaltyLookup'
import { useTypedSelector } from '@/store/index'
import { Party } from '@/api/interface/party'
import { CustomField } from '@/api/interface/custom-field'
import { Location } from '@/api/interface/location'
import { WaitTime } from '@/api/interface/wait-time'
import {
  CustomFieldVisibility,
  isCustomFieldVisible,
} from '@/consts/CustomFieldVisibility'
import {
  customFieldsValidator,
} from '@/utils/formik-validators'
import { mapqueueselectOptions } from '@/utils/queue-utils'
import { getDefaultCustomFieldValue } from '@/utils/util-functions'
import useIsUsingCustomQueueSelector from '@/hooks/useIsUsingCustomQueueSelector'
import CreateEditPartyCustomQueueSelector from './CreateEditPartyCustomQueueSelector'
import WaitTimeSelector from '@/components/common/forms/formik/WaitTimesSelector'
import InternationalPhoneInput from '@/components/common/forms/formik/InternationalPhoneInput'
import { intlPhoneTest } from '@/utils/formik-validators'
import { Business } from '@/api/interface/business'
import { checkIfDialCodeOnly } from '@/utils/formik-validators'
import useFilteredParties from '@/hooks/useFilteredParties'
import SwitchInput from '@/components/common/forms/formik/SwitchInput'
import BookingDateTimeSelector from '@/components/common/forms/BookingDateTimeSelector'
import dayjs from 'dayjs'
import {
  cleanTimezone,
  getClosest5MinsToNow,
  validateHrsMinsSecsTimeString,
} from '@/utils/time-utils'
import useBookingParties from '@/hooks/api-hooks/useBookingParties'

interface Props {
  actions: Record<string, Function>
  customFields: Array<CustomField>
  parties: Array<Party>
  selectedLocation: Location
  waitTimes: Array<WaitTime>
  business?: Business
}

function CreateEditPartyForm({
  actions,
  customFields,
  parties,
  selectedLocation,
  waitTimes,
  business,
}: Props) {
  const dispatch = useDispatch()

  const { partyId } = useTypedSelector((state) => state.selected)
  const { isUsingCustomQueueSelector, allStandardQueueOptions } = useIsUsingCustomQueueSelector(false)

  const defaultCountryISO  = useMemo(() => {
    return business?.address?.country?.toLowerCase()
  }, [business])
  
  const { 
    customQueueSelectOptions, 
    standardQueueSelectOptions, 
    isUsingBothQueueSelectors
  } = useTypedSelector((state) => state.queueSelect)

  const { mutateParties } = useFilteredParties()
  const { mutateBookingCounts } = useBookingParties()

  const selectedParty = useMemo(
    () => getSelectedObjectById<Party>(partyId, parties),
    [partyId, parties]
  )

  const queueSelectOptions = useMemo(
    () => mapqueueselectOptions(selectedLocation?.enabledQueues, waitTimes),
    [selectedLocation]
  )

  const [isFormDirty, setIsFormDirty] = useState(false)
  const [loyaltyData, setLoyaltyData] = useState(null)
  const [isGuestLookingUp, setIsGuestLookingUp] = useState(false)
  const [isGuestLookupSuccess, setIsGuestLookupSuccess] = useState(null)
  const [customQueueSelectValue, setCustomQueueSelectValue] = useState(() => {
    const qsCf = selectedParty?.customFields.find(
      (cf) => cf.type === 'queueselect'
    )
    const optionByQueueId = qsCf?.options.find(
      (option) => option.valueMeta === selectedParty?.queueId
    )
    return qsCf?.value || customQueueSelectOptions?.[0]?.value
  })

  const [guestQueueId, setGuestQueueId] = useState(() => {
    if (!isUsingCustomQueueSelector) {
      return selectedParty?.queueId || queueSelectOptions?.[0]?.value
    }
    if (isUsingCustomQueueSelector && !isUsingBothQueueSelectors) {
      return selectedParty?.queueId || customQueueSelectOptions?.[0]?.data?.valueMeta
    }
    if (isUsingCustomQueueSelector && isUsingBothQueueSelectors && selectedParty?.queueId) {
      return selectedParty?.queueId || ''
    }
    if (isUsingCustomQueueSelector && isUsingBothQueueSelectors && !selectedParty?.queueId) {
      return customQueueSelectOptions?.[0]?.data?.valueMeta
    }
    return queueSelectOptions?.[0]?.id
  })

  const initGuestQueueId = useMemo(() => {
    if (!isUsingCustomQueueSelector) {
      return selectedParty?.queueId || queueSelectOptions?.[0]?.value
    }
    if (isUsingCustomQueueSelector && !isUsingBothQueueSelectors) {
      return selectedParty?.queueId || customQueueSelectOptions?.[0]?.data?.valueMeta
    }
    if (isUsingCustomQueueSelector && isUsingBothQueueSelectors && selectedParty?.queueId) {
      return selectedParty?.queueId || ''
    }
    if (isUsingCustomQueueSelector && isUsingBothQueueSelectors && !selectedParty?.queueId) {
      return customQueueSelectOptions?.[0]?.data?.valueMeta
    }
    return queueSelectOptions?.[0]?.id
  }, [isUsingCustomQueueSelector, isUsingBothQueueSelectors, customQueueSelectOptions, selectedParty, queueSelectOptions])

  useEffect(() => {
    if (!guestQueueId) {
      setGuestQueueId(initGuestQueueId)
    }
  }, [guestQueueId, initGuestQueueId])

  // Support selecting queues not included on the custom queue selector
  // by displaying both queue selectors. When both standard and custom queue selectors are visible
  // standard queue selector should default to blank unless the party
  // is already in one of the available standard queues
  const initialQueueIdValue = useMemo(() => {
    if (isUsingCustomQueueSelector && isUsingBothQueueSelectors) {
      const queueIdInStandardOptions = standardQueueSelectOptions?.find((option) => option.value === guestQueueId)
      return queueIdInStandardOptions ? guestQueueId : ''
    }
    return guestQueueId
  }, [guestQueueId, isUsingCustomQueueSelector, isUsingBothQueueSelectors, standardQueueSelectOptions])

  const guestStatusOptions = Object.keys(PartyStatusEnum).map((key) => ({
    value: key,
    label: PartyStatusEnum[key],
  }))

  const guestStatusNotBookingOptions = Object.keys(
    PartyStatusNotBookingEnum
  ).map((key) => {
    return {
      value: key,
      label: PartyStatusNotBookingEnum[key],
    }
  })

  const queueCustomFields = useMemo(
    () =>
      customFields.filter(
        (x) =>
          x.enabledAt.includes(guestQueueId) &&
          isCustomFieldVisible(x.visibility, CustomFieldVisibility.webapp.value)
      ),
    [guestQueueId]
  )

  const customQueueSelectIndex = useMemo(() => {
    return queueCustomFields.reduce((acc, d, i) => {
      return d.type === 'queueselect' ? i : acc
    }, null)
  }, [queueCustomFields, customQueueSelectValue])

  const initialCfs = (cfs: CustomField[]) =>
    cfs?.map((x) => {
      const cfWithExistingValue = selectedParty?.customFields?.find(
        (y) => y.id === x.id
      )

      return {
        ...x,
        value:
          cfWithExistingValue?.value ||
          getDefaultCustomFieldValue(
            x.type,
            x.options,
            customQueueSelectValue,
            selectedParty?.queueId
          ),
      }
    })

  const customQueueSelectData = useMemo(() => {
    const customQueueSelectorField = initialCfs(queueCustomFields).find(
      (cf) => cf.type === 'queueselect'
    )
    return customQueueSelectorField
  }, [queueCustomFields])

  const customQueueSelectDummyFieldValue = useMemo(() => {
    const cfs = initialCfs(queueCustomFields).find(
      (cf) => cf.type === 'queueselect'
    )
    if (customQueueSelectValue) {
      return customQueueSelectValue
    }
    if (!!cfs?.value) {
      return cfs?.value
    }
    return ''
  }, [queueCustomFields, customQueueSelectValue])

  const getSuggestedWaitTimeByQueueId = useCallback(
    (queueId) => {
      const waitTime = waitTimes?.find((x) => x.queueId === queueId)

      return findNearestStdInterval(waitTime?.waitTimeS)
    },
    [waitTimes]
  )

  const getInitQuotedWaitTime = useCallback(() => {
    if (selectedParty) {
      return findNearestStdInterval(selectedParty?.quotedWaitTime)
    }
    if (getSuggestedWaitTimeByQueueId(guestQueueId)) {
      return getSuggestedWaitTimeByQueueId(guestQueueId)
    }
    return 0
  }, [guestQueueId, waitTimes])

  const initialBookingTime = useMemo(() => {
    return selectedParty?.bookingTimeLocal || selectedParty?.bookingTime
  }, [selectedParty])

  const bookingTimeDefault = useMemo(() => {
    return getClosest5MinsToNow()
  }, [])

  const initialValues = useMemo(() => {
    return {
      id: selectedParty?.id || null,
      locationId: selectedLocation?.id,
      businessId: business?.id,
      queueId: guestQueueId,
      quotedWaitTime: getInitQuotedWaitTime(),
      isBooking: selectedParty?.isBooking || false,
      bookingTime: initialBookingTime
        ? dayjs(cleanTimezone(initialBookingTime)).format('YYYY-MM-DD HH:mm:ss')
        : bookingTimeDefault?.dateTime,
      // track booking time parts independently so validation is more helpful
      bookingSubfieldDate: selectedParty?.bookingTime
        ? dayjs(cleanTimezone(selectedParty?.bookingTime)).format('YYYY-MM-DD')
        : bookingTimeDefault.date,
      bookingSubfieldTime: selectedParty?.bookingTime
        ? dayjs(cleanTimezone(selectedParty?.bookingTime)).format('HH:mm:ss')
        : bookingTimeDefault.time,
      source: selectedParty?.source || 'all',
      status: selectedParty?.status || 'waiting',
      customQueueSelect: customQueueSelectDummyFieldValue,
      guest: {
        id: selectedParty?.guestId || '',
        name: selectedParty?.guest?.name || '',
        phone: selectedParty?.guest?.phone || '',
        notes: selectedParty?.guest?.notes || '',
        email: selectedParty?.guest?.email || '',
      },
      customFields: initialCfs(queueCustomFields),
      referenceId: selectedParty?.referenceId,
    }
  }, [guestQueueId, customFields, selectedParty])

  const lookupPhoneNumber = async (phone, setFieldValue) => {
    setIsGuestLookingUp(true)
    // @TODO - update this for international phone lookup
    const [loyalGuest] = await getGuestByPhoneNumber(phone.replace(/\D/g, ''))

    setIsGuestLookingUp(false)

    if (loyalGuest) {
      setIsGuestLookupSuccess(true)
      setLoyaltyData(loyalGuest)

      //TODO: this might cause trouble but at least removed if lookup fails
      //TODO: this will also potentially break editing a guest if changing phone number
      setFieldValue('guest.id', loyalGuest.id)

      if (loyalGuest.name) {
        setFieldValue('guest.name', loyalGuest.name)
      }
      if (loyalGuest.notes) {
        setFieldValue('guest.notes', loyalGuest.notes)
      }
    } else {
      setIsGuestLookupSuccess(false)
      setLoyaltyData(null)

      setFieldValue('guest.id', null)
    }
  }

  const handleQueueIdChange = (
    queueId,
    values,
    setFieldValue,
    setErrors,
    setTouched
  ) => {
    const convertedQuotedWaitTime = getSuggestedWaitTimeByQueueId(queueId)

    setFieldValue('quotedWaitTime', convertedQuotedWaitTime)

    setFieldValue('queueId', queueId)
    setGuestQueueId(queueId)

    //* eliminate bug where custom fields have wrong value on queue change (formik limitation)
    //* See explanation in https://nextmeapp.atlassian.net/browse/NEX-181
    setFieldValue(
      'customFields',
      customFields
        .filter((x) => x.enabledAt.includes(queueId))
        .map((x) => ({
          ...x,
          value:
            values.customFields.find((y) => y.id === x.id)?.value ||
            getDefaultCustomFieldValue(
              x.type,
              x.options,
              customQueueSelectValue,
              queueId
            ),
        }))
    )

    setTimeout(() => {
      setErrors({})
      setTouched({
        quotedWaitTime: true,
      })
    })
  }

  // Wrapper around handleQueueIdChange to determine if custom queue select is allowed to call it.
  // Allowed if not using both selectors or if is using both selectors and the current queue is 
  // not in the list of standard queue options.
  const handleCustomQueueChange = useCallback((
    valueMeta,
    values,
    setFieldValue,
    setErrors,
    setTouched
  ) => {
    const queueIdInStandardOptions = Boolean(standardQueueSelectOptions?.find(option => option.value === values?.queueId))
    if (!isUsingBothQueueSelectors || (isUsingBothQueueSelectors && !queueIdInStandardOptions)) {
      handleQueueIdChange(
        valueMeta,
        values,
        setFieldValue,
        setErrors,
        setTouched)
    }
  }, [handleQueueIdChange, isUsingBothQueueSelectors, standardQueueSelectOptions])

  const validationSchema = Yup.object({
    quotedWaitTime: Yup.number()
      .min(0, 'Minimum wait time is 0')
      .max(315360000, 'Maximum 10 years'),
    customFields: customFieldsValidator(CustomFieldVisibility.webapp.value),
    guest: Yup.object({
      name: Yup.string().required('Required'),
      phone: Yup.string().test(
        'passes-google-libphonenumber-validation',
        'Invalid phone number',
        async (value) => await intlPhoneTest(value)
      ),
    }),
    bookingTime: Yup.string().when('isBooking', {
      is: true,
      then: Yup.string().test(
        'passes-dayjs-validation',
        'Invalid booking time',
        (value) => {
          return value !== undefined && dayjs(`${value}:00`).isValid()
        }
      ),
    }),
    bookingSubfieldDate: Yup.string().when('isBooking', {
      is: true,
      then: Yup.string().test(
        'passes-dayjs-validation',
        'Invalid booking date',
        (value) => {
          return (
            value !== undefined &&
            dayjs(value).isValid() &&
            dayjs().diff(value, 'day') <= 0
          )
        }
      ),
    }),
    bookingSubfieldTime: Yup.string().when('isBooking', {
      is: true,
      then: Yup.string().test({
        name: 'validateHrsMinssTimeStr',
        message: 'Invalid booking time',
        exclusive: false,
        params: {},
        test: (value, ctx) => {
          // first combine with date and check that it's valid and after now()
          const date = ctx.parent.bookingSubfieldDate
          const isFuture = dayjs(`${date} ${value}`).isAfter(dayjs())
          return validateHrsMinsSecsTimeString(value) && isFuture
        },
      }),
    }),
  })

  const cleanValuesForSubmission = (
    values,
    customQueueSelectValue?: string
  ) => {
    const { customFields, isBooking, status, bookingTime } = values

    const cleanedFields = customFields.map((cf) => {
      if (cf.type === 'queueselect') {
        return {
          ...cf,
          value: customQueueSelectValue || cf.options[0].value, // replace hardcode with first value from options
        }
      }

      return cf
    })

    return {
      ...values,
      status: !isBooking && status === 'scheduled' ? 'waiting' : status,
      bookingTime: !isBooking ? null : bookingTime,
      customFields: cleanedFields,
    }
  }

  const onSubmit = async (values) => {
    dispatch(setIsAlertOpen(false))

    setIsFormDirty(true)

    const cleanedValues = cleanValuesForSubmission(
      values,
      customQueueSelectValue
    )

    const submission = {
      ...cleanedValues,
      bookingTime:
        !!values?.bookingTime && values?.isBooking
          ? dayjs(values?.bookingTime).format('YYYY-MM-DD HH:mm')
          : '',
      guest: {
        ...cleanedValues.guest,
        phone: checkIfDialCodeOnly(cleanedValues.guest.phone)
          ? ''
          : cleanedValues.guest.phone,
      },
    }

    try {
      if (selectedParty) {
        await actions.onSubmitUpdate({
          ...submission,
          alertMessage: `${submission.guest.name}'s info has been saved.`,
          alertTitle: 'Success',
        })
      } else {
        delete submission.referenceId
        await actions.onSubmitCreate({
          ...submission,
          alertMessage: `${submission.guest.name} has been added to the waitlist.`,
          alertTitle: 'Success',
        })
      }

      await mutateParties()
      mutateBookingCounts()
      actions.mutateWaitTimes()

      dispatch(setIsSlideOpen(false))
    } catch (err) {
      console.error(printAxiosErrors(err))

      dispatch(
        setAlert({
          isOpen: true,
          title: err.response?.data?.message,
          type: 'error',
          message: printAxiosErrors(err, true),
        })
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={isFormDirty}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        setTouched,
        setErrors,
        setValues,
      }) => {
        //* reset custom fields if queue changes (because cf are related to queue)
        useEffect(() => {
          const updatedCfs = initialCfs(queueCustomFields)
          setValues((values) => ({
            ...values,
            customFields: updatedCfs,
          }))
        }, [queueCustomFields])

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <SlideContainer>
              <SlideContent>
                <SlideHeading
                  title={`${selectedParty ? 'Edit' : 'Add'} Guest`}
                  src="/icons/add-guest.svg"
                />
                {Boolean(selectedLocation?.isBookingsEnabled) && (
                  <SwitchInput
                    name={`isBooking`}
                    label={`Booking`}
                    onChange={(e) => {
                      if (e.target.checked && values.status === 'waiting') {
                        setFieldValue('status', 'scheduled')
                      }
                    }}
                  />
                )}

                {selectedParty && (
                  <>
                    <SelectField
                      fullWidth={true}
                      name="status"
                      label="Guest Status"
                      options={
                        values?.isBooking
                          ? guestStatusOptions
                          : guestStatusNotBookingOptions
                      }
                    />
                  </>
                )}

                <div style={{ position: 'relative' }}>
                  {defaultCountryISO && (
                    <InternationalPhoneInput
                      name="guest.phone"
                      label="Phone"
                      defaultCountry={defaultCountryISO}
                      setFieldValue={setFieldValue}
                      handleChange={(phone) => {
                        const val = phone
                        setFieldValue('guest.phone', val)
                        if (val?.length === 12) {
                          lookupPhoneNumber(val, setFieldValue)
                        } else {
                          setIsGuestLookupSuccess(null)
                          setLoyaltyData(null)
                        }
                      }}
                    />
                  )}

                  <PartyFormLoyaltyLookup
                    isGuestLookingUp={isGuestLookingUp}
                    isGuestLookupSuccess={isGuestLookupSuccess}
                    loyaltyData={loyaltyData}
                  />
                </div>

                <InputField
                  label="Name"
                  name="guest.name"
                  required
                  fullWidth={true}
                />
                <div
                  style={{
                    display: isUsingCustomQueueSelector && !isUsingBothQueueSelectors ? 'none' : 'block',
                  }}
                >
                  <SelectField
                    fullWidth={true}
                    name="queueId"
                    label="Queue"
                    options={partyId || !isUsingBothQueueSelectors ? allStandardQueueOptions : standardQueueSelectOptions}
                    showEmptyOption={isUsingCustomQueueSelector && isUsingBothQueueSelectors}
                    onChange={(e) => {
                      handleQueueIdChange(
                        e.target.value,
                        values,
                        setFieldValue,
                        setErrors,
                        setTouched
                      )
                    }}
                  />
                </div>

                {(isUsingBothQueueSelectors || isUsingCustomQueueSelector) && customQueueSelectData && (
                  <CreateEditPartyCustomQueueSelector
                    cf={customQueueSelectData}
                    handleQueueIdChange={handleCustomQueueChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    setErrors={setErrors}
                    setTouched={setTouched}
                    setCustomQueueSelectValue={setCustomQueueSelectValue}
                    currentQueueSelectOption={customQueueSelectValue}
                    cfIndex={customQueueSelectIndex}
                    waitTimes={waitTimes}
                  />
                )}

                {values?.isBooking ? (
                  <BookingDateTimeSelector
                    name="bookingTime"
                    dateLabel="Booking Date"
                    timeLabel="Booking Time"
                  />
                ) : (
                  <WaitTimeSelector
                    label="Estimated Wait Time"
                    name="quotedWaitTime"
                    setFieldValue={setFieldValue}
                    notes={null}
                    suggestedWaitTime={getSuggestedWaitTimeByQueueId(
                      guestQueueId
                    )}
                  />
                )}

                <CreateEditPartyCustomFields
                  customFields={queueCustomFields}
                  customQueueSelectValue={customQueueSelectValue}
                />
                
                {Boolean(values?.referenceId) && (
                  <InputField
                    label="Reference ID"
                    name="referenceId"
                    fullWidth={true}
                  />
                )}
                <TextareaField
                  label="Notes"
                  name="guest.notes"
                  fullWidth={true}
                />
              </SlideContent>
              <SlideActionButtons>
                <Button
                  className={classNames('btn w-100', buttonStyles.greenBtn)}
                  type="submit"
                  onClick={(e) => isSubmitting && e.preventDefault()}
                  disabled={isSubmitting}
                >
                  {selectedParty ? 'Sav' : 'Creat'}
                  {isSubmitting ? 'ing...' : 'e'}
                </Button>
              </SlideActionButtons>
            </SlideContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateEditPartyForm
